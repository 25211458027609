import axios from '@/common/axios'
import qs from 'qs'

export function addReturnApplication (data) {
  return axios({
    method: 'post',
    url: '/sale/returnApplication/add',
    data: qs.stringify(data)
  })
}

export function deleteReturnApplication (id) {
  return axios({
    method: 'delete',
    url: '/sale/returnApplication/delete/' + id
  })
}

export function updateReturnApplication (data) {
  return axios({
    method: 'put',
    url: '/sale/returnApplication/update',
    data: qs.stringify(data)
  })
}

export function selectReturnApplicationInfo (id) {
  return axios({
    method: 'get',
    url: '/sale/returnApplication/info/' + id
  })
}

export function selectReturnApplicationList (query) {
  return axios({
    method: 'get',
    url: '/sale/returnApplication/list',
    params: query
  })
}

export function qualityReturnApplication (data) {
  return axios({
    method: 'put',
    url: '/sale/returnApplication/qualityDept',
    data: qs.stringify(data)
  })
}

export function generalReturnApplication (data) {
  return axios({
    method: 'put',
    url: '/sale/returnApplication/general',
    data: qs.stringify(data)
  })
}
