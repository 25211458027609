<template>
  <div id="returnApplication">
    <el-dialog
      :title="returnApplicationFormTitle"
      width="1200px"
      :visible.sync="returnApplicationDialogVisible"
      :close-on-click-modal="false"
      @close="returnApplicationDialogClose"
    >
      <div id="printBox" style="padding-right: 20px">
        <div
          style="
            font-size: 24px;
            text-align: center;
            margin-bottom: 50px;
            font-weight: 600;
          "
        >
          退货申请单
        </div>
        <el-form
          ref="returnApplicationFormRef"
          :model="returnApplicationForm"
          :rules="returnApplicationFormRules"
          label-position="right"
          label-width="150px"
        >
          <el-row>
            <el-col :span="8">
              <el-form-item label="编号" prop="returnOrderNo">
                <el-input
                  v-model="returnApplicationForm.returnOrderNo"
                  placeholder="请输入编号"
                  clearable
                  :disabled="
                    returnApplicationFormTitle !== '新增退换货申请单' &&
                    returnApplicationFormTitle !== '修改退换货申请单' &&
                    returnApplicationFormTitle !== '退换货申请单详情'
                  "
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="申请日期" prop="applicationDate">
                <el-date-picker
                  v-model="returnApplicationForm.applicationDate"
                  placeholder="请选择申请日期"
                  value-format="yyyy-MM-dd"
                  :disabled="
                    returnApplicationFormTitle !== '新增退换货申请单' &&
                    returnApplicationFormTitle !== '修改退换货申请单' &&
                    returnApplicationFormTitle !== '退换货申请单详情'
                  "
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="客户名称" prop="customerId">
                <el-select
                  v-model="returnApplicationForm.customerId"
                  filterable
                  placeholder="请选择客户"
                  clearable
                  :disabled="
                    returnApplicationFormTitle !== '新增退换货申请单' &&
                    returnApplicationFormTitle !== '修改退换货申请单' &&
                    returnApplicationFormTitle !== '退换货申请单详情'
                  "
                  @change="customerChange"
                >
                  <el-option
                    v-for="item in customerList"
                    :key="item.id"
                    :label="item.customerName"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="地址" prop="contactAddress">
                <!-- <el-input
                  v-model="returnApplicationForm.contactAddress"
                  placeholder="请输入地址"
                  clearable
                  :disabled="returnApplicationFormTitle !== '新增退换货申请单'
                    && returnApplicationFormTitle !== '修改退换货申请单'
                    && returnApplicationFormTitle !== '退换货申请单详情'"
                /> -->
                <el-select
                  v-model="returnApplicationForm.contactAddress"
                  multiple
                  filterable
                  allow-create
                  default-first-option
                  placeholder="请输入地址"
                  :disabled="
                    returnApplicationFormTitle !== '新增退换货申请单' &&
                    returnApplicationFormTitle !== '修改退换货申请单' &&
                    returnApplicationFormTitle !== '退换货申请单详情'
                  "
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="联系人" prop="contactPerson">
                <el-input
                  v-model="returnApplicationForm.contactPerson"
                  placeholder="请输入联系人"
                  clearable
                  :disabled="
                    returnApplicationFormTitle !== '新增退换货申请单' &&
                    returnApplicationFormTitle !== '修改退换货申请单' &&
                    returnApplicationFormTitle !== '退换货申请单详情'
                  "
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="联系方式" prop="phone">
                <el-input
                  v-model="returnApplicationForm.phone"
                  placeholder="请输入联系方式"
                  clearable
                  :disabled="
                    returnApplicationFormTitle !== '新增退换货申请单' &&
                    returnApplicationFormTitle !== '修改退换货申请单' &&
                    returnApplicationFormTitle !== '退换货申请单详情'
                  "
                />
              </el-form-item>
            </el-col>
            <el-col :span="8" style="height: 62.8px">
              <el-form-item label="客户要求" prop="request">
                <el-radio-group
                  v-model="returnApplicationForm.request"
                  :disabled="
                    returnApplicationFormTitle !== '新增退换货申请单' &&
                    returnApplicationFormTitle !== '修改退换货申请单' &&
                    returnApplicationFormTitle !== '退换货申请单详情'
                  "
                >
                  <el-radio :label="1"> 退货 </el-radio>
                  <el-radio :label="2"> 换货 </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="产品编号" prop="productNo">
                <el-select
                  v-model="returnApplicationForm.productNo"
                  :placeholder="
                    returnApplicationFormTitle !== '退换货申请单详情(打印)'
                      ? '请选择产品'
                      : ''
                  "
                  clearable
                  filterable
                  @change="materialProductStockChange"
                >
                  <el-option
                    v-for="item in materialProductStockList"
                    :key="item.id"
                    :label="item.productNo"
                    :value="item.productNo"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="产品名称" prop="productName">
                <el-input
                  v-model="returnApplicationForm.productName"
                  placeholder="请输入产品名称"
                  clearable
                  :disabled="
                    returnApplicationFormTitle !== '新增退换货申请单' &&
                    returnApplicationFormTitle !== '修改退换货申请单' &&
                    returnApplicationFormTitle !== '退换货申请单详情'
                  "
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="规格型号" prop="spec">
                <el-input
                  v-model="returnApplicationForm.spec"
                  placeholder="请输入规格型号"
                  clearable
                  :disabled="
                    returnApplicationFormTitle !== '新增退换货申请单' &&
                    returnApplicationFormTitle !== '修改退换货申请单' &&
                    returnApplicationFormTitle !== '退换货申请单详情'
                  "
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="产品送货单号" prop="productDeliveryNoteNo">
                <el-input
                  v-model="returnApplicationForm.productDeliveryNoteNo"
                  :placeholder="
                    returnApplicationFormTitle !== '退换货申请单详情(打印)'
                      ? '请输入产品送货单号'
                      : ''
                  "
                  clearable
                  :disabled="
                    returnApplicationFormTitle !== '新增退换货申请单' &&
                    returnApplicationFormTitle !== '修改退换货申请单' &&
                    returnApplicationFormTitle !== '退换货申请单详情'
                  "
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="数量" prop="quantity">
                <el-input
                  v-model="returnApplicationForm.quantity"
                  placeholder="请输入数量"
                  clearable
                  :disabled="
                    returnApplicationFormTitle !== '新增退换货申请单' &&
                    returnApplicationFormTitle !== '修改退换货申请单' &&
                    returnApplicationFormTitle !== '退换货申请单详情'
                  "
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="金额" prop="amount">
                <el-input
                  v-model="returnApplicationForm.amount"
                  placeholder="请输入金额"
                  clearable
                  :disabled="
                    returnApplicationFormTitle !== '新增退换货申请单' &&
                    returnApplicationFormTitle !== '修改退换货申请单' &&
                    returnApplicationFormTitle !== '退换货申请单详情'
                  "
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="退换货数量" prop="returnQuantity">
                <el-input
                  v-model="returnApplicationForm.returnQuantity"
                  :placeholder="
                    returnApplicationFormTitle !== '退换货申请单详情(打印)'
                      ? '请输入退换货数量'
                      : ''
                  "
                  clearable
                  :disabled="
                    returnApplicationFormTitle !== '新增退换货申请单' &&
                    returnApplicationFormTitle !== '修改退换货申请单' &&
                    returnApplicationFormTitle !== '退换货申请单详情'
                  "
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="退换货金额（预算）" prop="returnAmount">
                <el-input
                  v-model="returnApplicationForm.returnAmount"
                  :placeholder="
                    returnApplicationFormTitle !== '退换货申请单详情(打印)'
                      ? '请输入退换货金额（预算）'
                      : ''
                  "
                  clearable
                  :disabled="
                    returnApplicationFormTitle !== '新增退换货申请单' &&
                    returnApplicationFormTitle !== '修改退换货申请单' &&
                    returnApplicationFormTitle !== '退换货申请单详情'
                  "
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="退（换）货原因" prop="returnReason">
                <el-input
                  v-model="returnApplicationForm.returnReason"
                  :placeholder="
                    returnApplicationFormTitle !== '退换货申请单详情(打印)'
                      ? '请输入退（换）货原因'
                      : ''
                  "
                  type="textarea"
                  :autosize="{ minRows: 4, maxRows: 8 }"
                  :disabled="
                    returnApplicationFormTitle !== '新增退换货申请单' &&
                    returnApplicationFormTitle !== '修改退换货申请单' &&
                    returnApplicationFormTitle !== '退换货申请单详情'
                  "
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="质量部检验意见"
                prop="qualityInspectionOpinion"
              >
                <el-input
                  v-model="returnApplicationForm.qualityInspectionOpinion"
                  :placeholder="
                    returnApplicationFormTitle !== '退换货申请单详情(打印)'
                      ? '请输入质量部检验意见'
                      : ''
                  "
                  type="textarea"
                  :autosize="{ minRows: 4, maxRows: 8 }"
                  :disabled="
                    returnApplicationFormTitle !==
                      '退换货申请单质量部检验意见' &&
                    returnApplicationFormTitle !== '退换货申请单详情'
                  "
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="总经理意见" prop="generalOpinion">
                <el-input
                  v-model="returnApplicationForm.generalOpinion"
                  :placeholder="
                    returnApplicationFormTitle !== '退换货申请单详情(打印)'
                      ? '请输入总经理意见'
                      : ''
                  "
                  type="textarea"
                  :autosize="{ minRows: 4, maxRows: 8 }"
                  :disabled="
                    returnApplicationFormTitle !== '退换货申请单总经理意见' &&
                    returnApplicationFormTitle !== '退换货申请单详情'
                  "
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div slot="footer">
        <el-button @click="returnApplicationDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="returnApplicationFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="编号">
        <el-input
          v-model="searchForm.returnOrderNo"
          placeholder="请输入编号"
          clearable
        />
      </el-form-item>
      <el-form-item label="客户名称">
        <el-input
          v-model="searchForm.customerName"
          placeholder="请输入客户名称"
          clearable
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          v-if="checkPermission(['RETURN_APPLY_ADD'])"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="returnApplicationPage.list"
      row-key="id"
      :cell-style="{ padding: '6px' }"
      :header-cell-style="{ background: '#f8f8f9' }"
      :row-class-name="tableRowClassName"
      :height="getTableHeight()"
    >
      <el-table-column prop="returnOrderNo" label="编号" />
      <el-table-column label="申请日期">
        <template slot-scope="scope">
          <span v-if="scope.row.applicationDate">{{
            scope.row.applicationDate.substring(0, 10)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="customerName" label="客户名称" />
      <el-table-column label="客户要求">
        <template slot-scope="scope">
          <span v-if="scope.row.request === 1">退货</span>
          <span v-if="scope.row.request === 2">换货</span>
        </template>
      </el-table-column>
      <el-table-column prop="contactAddress" label="地址" />
      <el-table-column prop="contactPerson" label="联系人" />
      <el-table-column prop="phone" label="联系方式" />
      <el-table-column prop="productDeliveryNoteNo" label="产品送货单号" />
      <el-table-column prop="productName" label="产品名称" />
      <el-table-column prop="spec" label="规格型号" />
      <el-table-column prop="quantity" label="数量" />
      <el-table-column prop="amount" label="金额" />
      <el-table-column prop="returnQuantity" label="退换货数量" />
      <el-table-column prop="returnAmount" label="退换货金额（预算）" />
      <el-table-column prop="returnReason" label="退（换）货原因" />
      <el-table-column prop="saleSignature" label="销售部" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.saleSignatureDate">{{
            scope.row.saleSignatureDate.substring(0, 10)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="qualityInspectionOpinion" label="质量部检验意见" />
      <el-table-column prop="qualitySignature" label="质量部" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.qualitySignatureDate">{{
            scope.row.qualitySignatureDate.substring(0, 10)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="generalOpinion" label="总经理意见" />
      <el-table-column prop="generalSignature" label="总经理" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.generalSignatureDate">{{
            scope.row.generalSignatureDate.substring(0, 10)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="200" fixed="right">
        <template slot-scope="scope">
          <el-button
            v-if="
              checkPermission(['RETURN_APPLY_DELETE']) && scope.row.status === 1
            "
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="
              checkPermission(['RETURN_APPLY_UPDATE']) && scope.row.status === 1
            "
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            v-if="
              checkPermission(['RETURN_APPLY_QUALITY']) &&
              scope.row.status === 1
            "
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleQuality(scope.$index, scope.row)"
          >
            质量部检验意见
          </el-button>
          <el-button
            v-if="
              checkPermission(['RETURN_APPLY_GENERAL']) &&
              scope.row.status === 2
            "
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleGeneral(scope.$index, scope.row)"
          >
            总经理意见
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
          <el-button
            type="text"
            icon="el-icon-printer"
            size="small"
            @click.stop="handleDayin(scope.$index, scope.row)"
          >
            打印
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="returnApplicationPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addReturnApplication,
  deleteReturnApplication,
  updateReturnApplication,
  selectReturnApplicationInfo,
  selectReturnApplicationList,
  qualityReturnApplication,
  generalReturnApplication,
} from "@/api/sale/returnApplication";
import { selectCustomerList } from "@/api/sale/customer";
import { selectMaterialProductStockList } from "@/api/storage/materialProductStock";
import moment from "moment";
import print from "print-js";

export default {
  data() {
    return {
      returnApplicationDialogVisible: false,
      returnApplicationFormTitle: "",
      returnApplicationForm: {
        id: "",
        returnOrderNo: "",
        applicationDate: "",
        customerId: "",
        customerName: "",
        request: "",
        contactAddress: "",
        contactPerson: "",
        phone: "",
        productDeliveryNoteNo: "",
        productNo: "",
        productName: "",
        spec: "",
        quantity: "",
        amount: "",
        returnQuantity: "",
        returnAmount: "",
        returnReason: "",
        qualityInspectionOpinion: "",
        generalOpinion: "",
        status: "",
        taskId: "",
      },
      returnApplicationFormRules: {
        returnOrderNo: [
          {
            required: true,
            message: "编号不能为空",
            trigger: ["blur", "change"],
          },
        ],
      },
      returnApplicationPage: {
        list: [],
        total: 0,
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        returnOrderNo: "",
        customerName: "",
      },
      customerList: "",
      materialProductStockList: [],
    };
  },
  created() {
    selectReturnApplicationList(this.searchForm).then((res) => {
      this.returnApplicationPage = res;
    });
    selectCustomerList().then((res) => {
      this.customerList = res.list;
    });
    selectMaterialProductStockList({ type: 1 }).then((res) => {
      this.materialProductStockList = res.list;
    });
  },
  methods: {
    returnApplicationDialogClose() {
      this.$refs.returnApplicationFormRef.resetFields();
    },
    returnApplicationFormSubmit() {
      if (this.returnApplicationFormTitle === "退换货申请单详情") {
        this.returnApplicationDialogVisible = false;
        return;
      }
      if (this.returnApplicationFormTitle === "退换货申请单详情(打印)") {
        print({
          printable: "printBox",
          type: "html",
          targetStyles: ["*"], // 重点  继承原来的样式，没有这个样式就是乱的
          maxWidth: 1800,
          // scanStyles: false,
          style: `div {
            font-size: 24px;
          }`,
        });
        this.returnApplicationDialogVisible = false;
        return;
      }
      this.$refs.returnApplicationFormRef.validate(async (valid) => {
        this.returnApplicationForm.contactAddress =
          this.returnApplicationForm.contactAddress.toString();
        if (valid) {
          if (this.returnApplicationFormTitle === "新增退换货申请单") {
            this.returnApplicationForm.id = "";
            this.returnApplicationForm.status = 1;
            await addReturnApplication(this.returnApplicationForm);
          } else if (this.returnApplicationFormTitle === "修改退换货申请单") {
            await updateReturnApplication(this.returnApplicationForm);
          } else if (
            this.returnApplicationFormTitle === "退换货申请单质量部检验意见"
          ) {
            this.returnApplicationForm.status = 2;
            await qualityReturnApplication(this.returnApplicationForm);
          } else if (
            this.returnApplicationFormTitle === "退换货申请单总经理意见"
          ) {
            this.returnApplicationForm.status = 3;
            await generalReturnApplication(this.returnApplicationForm);
          }
          this.returnApplicationPage = await selectReturnApplicationList(
            this.searchForm
          );
          this.returnApplicationDialogVisible = false;
        }
      });
    },
    handleAdd() {
      this.returnApplicationFormTitle = "新增退换货申请单";
      this.returnApplicationDialogVisible = true;
      this.returnApplicationForm.returnOrderNo =
        "TH" + moment().format("YYYYMMDD") + "-";
    },
    handleDelete(index, row) {
      this.$confirm("确认删除？", "提示", {
        type: "warning",
      }).then(async () => {
        await deleteReturnApplication(row.id);
        if (
          this.returnApplicationPage.list.length === 1 &&
          this.searchForm.pageNum > 1
        ) {
          this.searchForm.pageNum--;
        }
        this.returnApplicationPage = await selectReturnApplicationList(
          this.searchForm
        );
      });
    },
    handleUpdate(index, row) {
      this.returnApplicationFormTitle = "修改退换货申请单";
      this.returnApplicationDialogVisible = true;
      this.selectReturnApplicationInfoById(row.id);
    },
    handleQuality(index, row) {
      this.returnApplicationFormTitle = "退换货申请单质量部检验意见";
      this.returnApplicationDialogVisible = true;
      this.selectReturnApplicationInfoById(row.id);
    },
    handleGeneral(index, row) {
      this.returnApplicationFormTitle = "退换货申请单总经理意见";
      this.returnApplicationDialogVisible = true;
      this.selectReturnApplicationInfoById(row.id);
    },
    handleInfo(index, row) {
      this.returnApplicationFormTitle = "退换货申请单详情";
      this.returnApplicationDialogVisible = true;
      this.selectReturnApplicationInfoById(row.id);
    },
    handleDayin(index, row) {
      this.returnApplicationFormTitle = "退换货申请单详情(打印)";
      this.returnApplicationDialogVisible = true;
      this.selectReturnApplicationInfoById(row.id);
    },
    selectReturnApplicationInfoById(id) {
      selectReturnApplicationInfo(id).then((res) => {
        this.returnApplicationForm.id = res.id;
        this.returnApplicationForm.returnOrderNo = res.returnOrderNo;
        this.returnApplicationForm.applicationDate = res.applicationDate;
        this.returnApplicationForm.customerId = res.customerId;
        this.returnApplicationForm.customerName = res.customerName;
        this.returnApplicationForm.request = res.request;
        this.returnApplicationForm.contactAddress =
          res.contactAddress.split(",");
        this.returnApplicationForm.contactPerson = res.contactPerson;
        this.returnApplicationForm.phone = res.phone;
        this.returnApplicationForm.productDeliveryNoteNo =
          res.productDeliveryNoteNo;
        this.returnApplicationForm.productName = res.productName;
        this.returnApplicationForm.spec = res.spec;
        this.returnApplicationForm.quantity = res.quantity;
        this.returnApplicationForm.amount = res.amount;
        this.returnApplicationForm.returnQuantity = res.returnQuantity;
        this.returnApplicationForm.returnAmount = res.returnAmount;
        this.returnApplicationForm.returnReason = res.returnReason;
        this.returnApplicationForm.qualityInspectionOpinion =
          res.qualityInspectionOpinion;
        this.returnApplicationForm.generalOpinion = res.generalOpinion;
        this.returnApplicationForm.status = res.status;
        this.returnApplicationForm.taskId = res.taskId;
      });
    },
    handleSearch() {
      this.searchForm.pageNum = 1;
      selectReturnApplicationList(this.searchForm).then((res) => {
        this.returnApplicationPage = res;
      });
    },
    pageNumChange(pageNum) {
      this.searchForm.pageNum = pageNum;
      selectReturnApplicationList(this.searchForm).then((res) => {
        this.returnApplicationPage = res;
      });
    },
    pageSizeChange(pageSize) {
      this.searchForm.pageSize = pageSize;
      this.searchForm.pageNum = 1;
      selectReturnApplicationList(this.searchForm).then((res) => {
        this.returnApplicationPage = res;
      });
    },
    tableRowClassName({ row, rowIndex }) {
      if (this.checkPermission(["RETURN_APPLY_QUALITY"]) && row.status === 1) {
        return "review";
      } else if (
        this.checkPermission(["RETURN_APPLY_GENERAL"]) &&
        row.status === 2
      ) {
        return "review";
      }
      return "";
    },
    customerChange(value) {
      if (value) {
        let customer = this.customerList.find((item) => item.id === value);
        this.returnApplicationForm.customerName = customer.customerName;
        this.returnApplicationForm.contactAddress =
          customer.receivingAddress.split(",");
        this.returnApplicationForm.contactPerson = customer.contactPerson;
        this.returnApplicationForm.phone = customer.phone;
      } else {
        this.returnApplicationForm.customerName = "";
        this.returnApplicationForm.contactAddress = "";
        this.returnApplicationForm.contactPerson = "";
        this.returnApplicationForm.phone = "";
      }
    },
    materialProductStockChange(value) {
      if (value) {
        let materialProductStock = this.materialProductStockList.find(
          (item) => item.productNo === value
        );
        console.log(materialProductStock);
        this.returnApplicationForm.productName =
          materialProductStock.productName;
        this.returnApplicationForm.spec = materialProductStock.spec;
      } else {
        this.returnApplicationForm.productName = "";
        this.returnApplicationForm.spec = "";
      }
    },
  },
};
</script>

<style></style>
